<script setup lang="ts">
import type { AsfPriceProps } from '@ui/types'

const props = withDefaults(defineProps<AsfPriceProps>(), {
  large: false,
  zeroAsFree: false
})

const { price } = usePrice()

const priceRanges = computed(() => getProductPriceRange(props.prices))

const isNotApplicable = computed(() => !props.prices || props.prices.length === 0)
const isRangePrice = computed(() => props.prices?.length > 1 && priceRanges.value.min !== priceRanges.value.max)
const startPrice = computed(() => {
  if (isRangePrice.value) {
    return priceRanges.value.min
  }

  const firstPrice = props.prices[0]

  if (firstPrice) {
    return firstPrice.regular
  }

  return undefined
})
const endPrice = computed(() => (isRangePrice.value ? priceRanges.value.max : props.prices[0]?.special))
const currencyCode = computed(() => props.prices[0]?.currencyCode)
const isSale = computed(() => !isRangePrice.value && startPrice.value && endPrice.value)
const useScreenReaderPhrases = computed(() => isRangePrice.value || isSale.value)

const isFree = computed(
  () =>
    props.zeroAsFree &&
    (isNotApplicable.value || (startPrice.value === 0 && (endPrice.value === undefined || endPrice.value === 0)))
)
</script>
<template>
  <div class="asf-price" :class="{ 'is-large': large }">
    <span v-if="isFree" class="asf-price-item" :class="{ 'is-free': isFree }">{{ $t('price.free') }} </span>
    <span
      v-else-if="isNotApplicable"
      v-e2e="'na-price'"
      class="asf-price-item"
      :class="{ 'is-not-applicable': isNotApplicable }"
    >
      {{ $t('price.notapplicable') }}
    </span>
    <template v-else>
      <span v-if="useScreenReaderPhrases" class="asf-price-phrase" v-e2e="isRangePrice ? 'price-from' : 'price-was'">
        <template v-if="isRangePrice">{{ $t('price.from') }}</template>
        <template v-else>{{ $t('price.was') }}</template>
      </span>

      <del v-if="isSale" class="asf-price-item is-old" v-e2e="'old-price'">{{
        startPrice ? price(startPrice, currencyCode) : $t('price.notapplicable')
      }}</del>
      <span v-else class="asf-price-item" v-e2e="'regular-price'">{{
        startPrice ? price(startPrice, currencyCode) : $t('price.notapplicable')
      }}</span>

      <span v-if="isRangePrice" class="asf-price-divider"> - </span>
      <span v-if="useScreenReaderPhrases" class="asf-price-phrase" v-e2e="isRangePrice ? 'price-to' : 'price-is'">
        <template v-if="isRangePrice">{{ $t('price.to') }}</template>
        <template v-else>{{ $t('price.is') }}</template>
      </span>
      <span v-if="endPrice" class="asf-price-item" v-e2e="'sale-price'" :class="{ 'is-new': isSale }">{{
        price(endPrice, currencyCode)
      }}</span>
    </template>
  </div>
</template>
<style lang="postcss">
@import '@components/atoms/Price/Price.css';
</style>
